<template>
  <v-container>
    <app-error :error="error" v-if="error" />
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <v-card>
          <v-card-title>{{ $t("settings.synchronizeWooTitle") }}</v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="synchronize()" :loading="!!synchronizing">
              {{ $t("settings.synchronizeWoo") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      synchronizing: null,
      interval: null,
      error: null
    };
  },
  mounted() {
    this.fetch();
    this.interval = setInterval(() => this.fetch(), 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    synchronize() {
      this.synchronizing = true;
      this.error = null;
      this.$store
        .dispatch("settings/synchronize")
        .then(s => (this.synchronizing = s))
        .catch(err => (this.error = err));
    },
    fetch() {
      this.$store
        .dispatch("settings/getSynchronize")
        .then(s => this.$nextTick(() => (this.synchronizing = s)))
        .catch(err => (this.error = err));
    }
  }
};
</script>

<style></style>
